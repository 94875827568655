$light-grey: #aaa;
$medium-grey: #5a5a5a;

.about-blurb {
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
  margin: 1.2em 0;

  p {
    margin: 0;
  }

  p:not(:first-child) {
    margin-top: 0.5em;
  }
}

:not(header) > h2 {
  padding-bottom: 10px;
  border-bottom: 1px solid RGBA(213 213 213);  /* stylelint-disable-line function-name-case */
}

/* Heading anchor links */

.anchor-link {
  color: lightgrey;
  transform: scale(0.75);
}

.anchor-link:hover {
  color: unset;
}

/* Code blocks */

code {
  background: #f5f5f5;
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  color: #666;
  border-radius: 4px;
  padding: 0.15em 0.3em;
  border: 1px solid #ccc;
}

:not(pre) > code {
  font-size: 0.8em;
  vertical-align: middle;
  white-space: nowrap;    // Prevent being split into separate lines
}

pre {
  margin: 1em 0;
}

pre > code {
  display: block;
  padding: 0.75em 1em;
  overflow-x: auto;
  min-width: 100%;
  font-size: 0.75em;
  width: 0;
}

code.has-jax {
  -webkit-font-smoothing: antialiased;
  background: inherit !important;
  border: none !important;
  font-size: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  color: #333 !important;
}

/* Header nav */

header > nav {
  a {
    color: $medium-grey;
    padding: 8px 12px;
    vertical-align: middle;

    // Don't show clickable affordance (underline) for active section
    &.header__item--selected:hover {
      text-decoration: none;
    }
  }

  .header__item--selected {
    font-weight: bold;
  }
}

/* Icons */
span.fontawesome-inline-svg > svg {
  display: inline-block;
  height: 1.2em;
  width: 1.2em;
  top: 0.15em;
  position: relative;
}

.link--no-hover:hover {
  text-decoration: none !important;

  span.fontawesome-inline-svg {
    transform: scale(1.1);
  }
}

/* BLOG POST LIST PAGE */
section.flex-article-list {
  margin: 3em 0;

  & > h4 {
    margin: 1em 0;
    font-weight: bold;
  }

  & > div.single-post {
    // line-height: 2em;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0.6em 0;
  }

  & time {
    font-family: monospace;
    color: #666;
    font-size: 0.875em;
    margin-left: 2em;
    white-space: nowrap;
    width: min-content;
  }

  & aside {
    font-size: 0.8em;
  }

  a {
    color: #333 !important;
  }
}

/* Homepage */

h1.hello {
  text-align: center;
  margin: 3em;
  font-size: 3em;
}

div.hero {
  font-size: 4em;
  text-align: center;
}

/* Lists */

li {
  margin: 0.25em 0;
}

ol > li {
  list-style: decimal;
  margin-left: 2em;
}

ul > li {
  list-style: disc;
  margin-left: 2em;
}

// No margin on nested lists
ul ul,
ul ol,
ol ul,
ol ol {
  margin: 0;
}

/* PAGE METADATA */

div.page-metadata {
  display: flex;
  flex-flow: row wrap;
  column-gap: 20px;
  row-gap: 5px;
  font-size: 0.9em;
  color: $medium-grey;
  line-height: 1.8;
  margin-top: 0.4em;

  .page-tags {
    display: inline;
    a { color: $medium-grey; }
  }

  // Manual fixes for tag icon looking out-of-place relative to other icons
  .tag-section svg {
    top: 0.25em;
    margin-right: 0.1em;
  }
}

/* DISQUS */

#disqus_thread {
  overflow: hidden;

  iframe {
    margin-bottom: -54px;
  }
}
