/* Core layout (incl. media queries)

body
aside#left-sidebar
div#toc-wrapper
h4
nav#TableOfcontents
main
header
div.post
footer

*/

/* || Default */

body {
  display: grid;
  justify-content: center;
  grid-template-rows: auto;
  grid-template-columns: 0fr 1fr 0fr;
  grid-template-areas:
    "tlc header trc"
    "4 title-group 6"
    "toc main notes"
    " toc comments notes"
    "footer footer footer";
}

#left-sidebar,
.notes {
  display: none;
}

header.primary {
  grid-area: header;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  padding: 20px 20px 0;

  & > h2 {
    margin: 0;
    float: unset;
  }

  @media (max-width: 600px) {
    row-gap: 20px;

    & > h2,
    & > nav {
      display: flex;
      justify-content: space-around;
      flex-wrap: nowrap;
      width: 100%;
      min-width: fit-content;
    }
  }
}

main {
  // width: 100%;
  display: contents;

  // Apply padding to all direct children
  // Necessary b/c of display: contents;
  & > * { padding: 0 20px; }
}

article.content { grid-area: main; }
.title-group { grid-area: title-group; }
.comments { grid-area: comments; }
footer { grid-area: footer; }

/* Layout for post-type pages (blog, notebook */

// 800px - 1200px (smoothly allocate space for ToC)

@media (min-width: 800px) {
  body.single {
    grid-template-columns: 1fr 800px 0fr;
  }

  /* Layout for non-post pages (home, list, etc.) */
  body.list {
    grid-template-columns: 1fr 800px 1fr;
  }
}

// 1100px (show ToC)

@media (min-width: 1100px) {
  body.single {
    grid-template-columns: 300px 800px 1fr;
  }

  #left-sidebar {
    grid-area: toc;
    display: unset;
    margin-left: 2em;
    padding-right: 1em;
    margin-right: 1em;
    border-right: 1px solid #efefef;
  }
}

@media (min-width: 1400px) {
  body.single {
    grid-template-columns: 300px 800px 300px;
  }

  .notes {
    grid-area: notes;
    display: unset;
  }
}
