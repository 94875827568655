html {
  scroll-behavior: smooth;
}

#left-sidebar {
  // Make it sticky when scrolling down
  position: sticky;
  top: 4em;
  align-self: start;

  // Make the ToC appear lower initially.
  margin-top: 24px;
  font-size: smaller;

  h4 {
    margin-top: -28px;
    margin-bottom: 0.5em;
  }

  nav {
    float: unset;
    text-align: left;
    display: inline-block;
  }

  ul,
  li {
    list-style-type: none !important;
  }

  li {
    // First level li has zero indent
    line-height: 1.2;
    margin-left: 0;
    margin-bottom: 0.6em;
  }

  li > a:hover {
    text-decoration: none;
  }

  ul {
    margin-top: 0.6em;
  }

  // Subsequent levels have some
  li ul li {
    margin-left: 1em;
  }

  li > a:not(:hover):not(.active) {
    color: RGB(136 136 136);  /* stylelint-disable-line function-name-case */
  }

  // Highlight active
  a.active {
    color: #333;
    font-weight: 600;
  }
}
