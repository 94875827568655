// https://scripter.co/sidenotes-using-only-css

// General
// ----------------------------------------------------------------------

$medium-grey: #5a5a5a;

.sidenote {
  box-sizing: border-box;
}

.sidenote-checkbox {
  display: none;
}

.sidenote-content {
  font-size: 80%;
  position: relative;
  margin: 0px;
  color: $medium-grey;

  // Hide the backlink icon
  & > a.footnote-backref {
    display: none;
  }

}

/* Sidenote counter */

body {
  counter-reset: sidenote-counter;
}
.sidenote {
  counter-increment: sidenote-counter;
}

/* Counter in the main body. */
.sidenote-label::after {
  content: counter(sidenote-counter);
  vertical-align: super;
  margin: 0 0.1em;
  font-size: 0.7em;
  // color: $medium-grey;
  // font-weight: bold;
}

/* Counter before the sidenote in the margin. */
.sidenote-content::before {
  content: counter(sidenote-counter)".";
  position: relative;
  vertical-align: baseline;
  padding-right: 0.1em;
  font-size: 0.9em;
  // font-weight: bold;
}

// Hide the regular end-of-page footnotes section
div.footnotes { display: none; }

// Inline UX
// ----------------------------------------------------------------------

@media (max-width: 1399px) {

  .sidenote-label {
    cursor: pointer;
    color: #0085bd;
  }

  .sidenote-content {
    float: left;
    margin: 1em;
    display: none;
    
    .sidenote-checkbox:checked ~ & {
      display: block;
      // width: 100%;
      left: 0;
      right: 0;
    }
  }

}

// Wide viewport
// ----------------------------------------------------------------------

@media (min-width: 1400px) {
  .sidenote-content {
    float: right;
    clear: right;
    width: 280px;
    margin-right: -300px;
    padding: 5px;
    border-radius: 5x;
  }

  // Highlight content when hovering on label
  .sidenote-label:hover ~ .sidenote-content {
    background-color: rgba(255, 255, 146, 0.5);
  }

  // Highlight label when hovering on content
  .sidenote:hover {
    background-color: rgba(255, 255, 146, 0.5);
  }
}
